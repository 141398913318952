<template>
  <div>
    <a-modal
      v-model="visibleModalManageIzin"
      class="mod"
      :title="status.isEdit ? 'Form Ubah Izin' : 'Form Tambah Izin'"
      @cancel="toggleModalManageIzin"
      centered
    >
      <form-izin
        :dataIzin="newIzin"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageIzin">
          Batal
        </a-button>
        <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManageIzin" @click="handleOkModalManageIzin">
          {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button @click.prevent="toggleModalManageIzin('add')" size="large" type="primary"><a-icon type="plus" />Tambah Izin</a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="izinColumns"
        :data-source="izinDataTable"
        :pagination="pagination"
        :loading="loadingTable"
        bordered
      >
        <div slot="aksi" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManageIzin('edit', record)"
            class="mr-3 text-warning border border-warning"
            size="large"
            icon="edit"
          />
          <a-button
            @click.prevent="handleDeleteIzin(record)"
            class="text-danger border border-danger"
            size="large"
            icon="delete"
          />
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const FormIzin = () => import('@/components/app/AdminHR/Izin/FormIzin')
const izinColumns = [
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    width: 300,
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Regulasi',
    dataIndex: 'regulasi',
    key: 'regulasi',
    width: 150,
    align: 'center',
    scopedSlots: { customRender: 'regulasi' },
  },
  {
    title: 'Durasi Max',
    dataIndex: 'durasiMax',
    key: 'durasiMax',
    width: 100,
    align: 'center',
    scopedSlots: { customRender: 'durasiMax' },
  },
  {
    title: 'Tipe Durasi',
    dataIndex: 'tipeDurasi',
    key: 'tipeDurasi',
    width: 100,
    align: 'center',
    scopedSlots: { customRender: 'tipeDurasi' },
  },
  {
    title: 'Deskripsi',
    dataIndex: 'deskripsi',
    key: 'deskripsi',
    width: 300,
    scopedSlots: { customRender: 'deskripsi' },
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    width: 150,
    align: 'center',
  },
]
export default {
  components: {
    FormIzin,
  },
  data() {
    return {
      izinColumns,
      izinDataTable: [],
      pagination: {},
      loadingTable: false,
      visibleModalManageIzin: false,
      loadingActionModalManageIzin: false,
      status: {
        isEdit: false,
        isCreate: false,
      },
      newIzin: {
        nama: null,
        regulasi: null,
        deskripsi: null,
        tipe: null,
        durasi_maksimal: null,
        tipe_durasi: null,
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload

      this.newIzin[column] = value
    },
    toggleModalManageIzin(status, data) {
      this.visibleModalManageIzin = !this.visibleModalManageIzin
      if (!this.visibleModalManageIzin) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newIzin = {
            nama: null,
            regulasi: null,
            deskripsi: null,
            tipe: null,
            durasi_maksimal: null,
            tipe_durasi: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newIzin = {
            nama: data.nama,
            regulasi: data.regulasi,
            deskripsi: data.deskripsi,
            tipe: data.tipe,
            durasi_maksimal: data.durasiMax,
            tipe_durasi: data.tipeDurasi,
          }
        }
      }
    },
    handleOkModalManageIzin() {
      const content = `${this.status.isEdit ? 'Apakah anda yakin ingin mengubah izin ini? perubahan ini akan mempengaruhi sistem absensi pegawai' : 'Apakah anda yakin ingin membuat izin baru ini? perubahan ini akan disimpan kedalam sistem'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Semua kolom wajib diisi',
        })
      } else {
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageIzin = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataIzin: this.newIzin,
            } : {
              dataIzin: this.newIzin,
            }
            this.$store.dispatch(`izin/${this.status.isEdit ? 'EDIT' : 'POST'}_IZIN`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageIzin = false
                this.toggleModalManageIzin()
                this.fetchDataIzin()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Izin berhasil diubah' : 'Izin baru berhasil ditambahkan'
                  this.$notification.success({
                    message: 'Berhasil',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Izin gagal diubah' : 'Izin baru gagal ditambahkan'
                  this.$notification.error({
                    message: 'Gagal',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleDeleteIzin(data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin menghapus izin <b>{data.nama}</b> ? perubahan ini akan mempengaruhi sistem absensi pegawai</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.loadingDeleteIzin = true
          this.$store.dispatch('izin/DELETE_IZIN', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataIzin()
              this.loadingDeleteIzin = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    'Izin berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Izin gagal dihapus',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePeriode = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
    fetchDataIzin(params = { page: '', order: '', search: '', sortBy: '', type: '' }) {
      this.loadingTable = true
      this.$store
        .dispatch('izin/FETCH_IZIN', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, type: params.type })
        .then(res => {
          // console.log('res', res)
          const dataIzin = res.izin
          const totalPage = res.total
          this.loadingTable = false
          const pagination = { ...this.pagination }
          pagination.total = totalPage
          this.pagination = pagination
          this.izinDataTable = dataIzin.map(el => {
            return {
              key: el.id,
              id: el.id,
              nama: el.nama,
              regulasi: el.regulasi,
              deskripsi: el.deskripsi,
              tipe: el.tipe,
              durasiMax: el.durasi_maksimal,
              tipeDurasi: el.tipe_durasi,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataIzin()
  },
  computed: {
    isValidateForm() {
      if (this.newIzin.nama && this.newIzin.tipe && this.newIzin.regulasi) {
        return false
      }
      return true
    },
  },
}
</script>

<style>

</style>
